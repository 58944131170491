// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/navigation/Tabbar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/navigation/Tabbar.tsx");
  import.meta.hot.lastModified = "1728030536059.7163";
}
// REMIX HMR END

import { Badge, Box, Center, Spinner, Tab, TabList, Tabs, Text, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { BsBookmark, BsCardHeading, BsChat, BsFire } from "react-icons/bs";
import { useAuth } from "~/utils/userUtils";
import { Link as ReactRouterLink } from "react-router-dom";

// Tabbar.tsx

export const Tabbar = props => {
  _s();
  const {
    selectedTab,
    tab,
    root,
    description
  } = props;
  const [isAtEnd, setIsAtEnd] = useState(false);
  const tabListRef = useRef(null);
  const {
    user
  } = useAuth();
  const [loading, setLoading] = useState(false);
  const checkIfScrolledToEnd = () => {
    const {
      scrollWidth,
      clientWidth,
      scrollLeft
    } = tabListRef.current;
    const isScrolledToEnd = scrollLeft + clientWidth >= scrollWidth - 1;
    // console.log("isScrolledToEnd", isScrolledToEnd,scrollLeft+ clientWidth, scrollWidth)
    setIsAtEnd(isScrolledToEnd);
  };
  useEffect(() => {
    const tabList = tabListRef.current;
    tabList.addEventListener("scroll", checkIfScrolledToEnd);
    return () => {
      tabList.removeEventListener("scroll", checkIfScrolledToEnd);
    };
  }, []);
  function onSelectedTab(index) {
    setLoading(true);
  }
  useEffect(() => {
    setLoading(false);
  }, [selectedTab]);
  return <Box maxW={{
    base: "100vw",
    md: "100%"
  }} mb={{
    base: "2",
    md: "4"
  }} pos={"relative"}
  // overflow={"hidden"}
  >
      <Tabs isLazy defaultIndex={selectedTab} display={"flex"} overflow={"scroll"} className="story-box" ref={tabListRef} onChange={onSelectedTab}>
        <TabList pt="3" mb={description ? "0" : "5"} w={{
        base: "auto",
        md: "100%"
      }}>
          <Tab as={ReactRouterLink} to={tab ? `/` : `${root}`}>
            <Text pr="5px">
              <BsCardHeading />
            </Text>
            Aktuell
          </Tab>

          <Tab as={ReactRouterLink} to="/deals/hot">
            <Text pr="5px">
              <BsFire />
            </Text>
            Heiß
          </Tab>

          <Tab as={ReactRouterLink} to="/deals/diskutiert">
            <Text pr="5px">
              <BsChat />
            </Text>
            Diskutiert
          </Tab>

          <Tab as={ReactRouterLink} to="/deals/bookmark">
            <Text pr="5px">
              <BsBookmark />
            </Text>
            <Text pr="5px">Lesezeichen</Text>
            {user?.bookmarks && <Badge>{user.bookmarks.length}</Badge>}
          </Tab>
        </TabList>
      </Tabs>
      {description && <Box p="3" pb="0" textAlign={"center"} fontStyle={""} fontWeight={"hairline"}>{description}</Box>}
      <Center>{loading && <Spinner size="md" mt={5} mb={3} />}</Center>
      {!isAtEnd && <Box w={"50px"} pos={"absolute"} right={0} top={0} bottom={0} bgGradient={useColorModeValue("linear(to right, transparent, white)", "linear(to right, transparent ,gray.800)")} display={{
      base: "block",
      md: "none"
    }}></Box>}
    </Box>;
};
_s(Tabbar, "6r+FwVhMouD2S3TFPrYRGNXQTFw=", false, function () {
  return [useAuth, useColorModeValue];
});
_c = Tabbar;
var _c;
$RefreshReg$(_c, "Tabbar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;